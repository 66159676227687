<template>
  <v-card :class="drawer ? 'drawer-open' : 'drawer-closed'" class="drawer-detection-container">
    <v-data-table
      class="custom-table-display"
      :headers="headers"
      :items="pDisplayItems"
      :loading="pCurrentPageLoading"
      :server-items-length="pTotalCount"
      @update:options="paginate($event)"
      :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            v-if="pAllowToCreateUser"
            color="primary"
            class="custom-table-add-btn"
            @click="$emit('onOpenEditDialog', {})"
          >
            New User
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{ item.getUserTypeDisplayName() }}
      </template>

      <template v-slot:[`item.hostMemberships`]="{ item }">
        <div class="host-membership">
          <div class="d-flex align-center" v-for="(it, i) in item.hostMemberships" :key="i">
            <HostRoles :host-roles="it.roles" class="mr-1" />
            <span v-if="pShowHostName">
             of
            <router-link
                class="router-link-active"
                :to="`/hosts/${it.host.id}`"
            >
              {{it.host.name}}
            </router-link>
          </span>
            <div v-if="!item.hostMemberships.length">No host memberships</div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex">
          <div
            :class="item.inactive ? 'red' : 'green'"
            class="item-circle mr-2"
          ></div>

          <span v-if="item.inactive"> Inactive </span>
          <span v-else> Active </span>

          <v-btn
            v-if="item.inactive"
            class="ml-2 custom-table-activate-btn"
            x-small
            :disabled="item.id == user.id"
            @click="activateUser(item)"
          >
            Activate
          </v-btn>

          <v-btn
            v-else
            class="ml-2 custom-table-deactivate-btn"
            x-small
            :disabled="item.id == user.id"
            @click="openDeleteUserDialog(item)"
          >
            Deactivate
          </v-btn>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          medium
          class="mr-2 custom-table-edit-btn"
          @click="$emit('onOpenEditDialog', item)"
          >mdi-pencil</v-icon
        >
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ moment(item.createdAt).format(dateTimeMomentFormat) }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ moment(item.updatedAt).format(dateTimeMomentFormat) }}
      </template>
    </v-data-table>
    <ConfirmationDialog ref="confirmationDialog" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Vue from "vue";
import {
  paginateTable, dateTimeMomentFormat, logAndExtractMessage, dismissAction,
} from "@/utils/utils";
import HostRoles from "@/components/custom-components/HostRoles";

export default {
  name: "UsersTableComponent",

  components: {
    HostRoles,
    ConfirmationDialog: () => import("../dialogs/ConfirmationDialog.vue"),
  },

  props: {
    pDisplayItems: Array,
    pCurrentPageLoading: Boolean,
    pTotalCount: Number,
    pLocalStorageTable: String,
    pStoreRef: String,
    pShowUserActions: {
      type: Boolean,
      default: true,
    },
    pShowHostName: {
      type: Boolean,
      default: true,
    },
    pAllowToCreateUser: {
      type: Boolean,
      default: false,
    },
    pShowUserType: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    moment,
    dateTimeMomentFormat,

    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },
  }),

  computed: {
    ...mapState("userModule", ["user"]),
    ...mapState("coreModule", ["drawer"]),

    headers() {
      const defaultHeaders = [
        {
          text: "First Name",
          value: "firstName",
          sortable: false,
        },
        {
          text: "Last Name",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Host Memberships",
          value: "hostMemberships",
          sortable: false,
        },
        { text: "Status", value: "status", sortable: false },
        { text: "Created at ", value: "createdAt", sortable: false },
        { text: "Updated at", value: "updatedAt", sortable: false },
      ];

      if (this.pShowUserType) {
        defaultHeaders.splice(3, 0, {
          text: "User Type",
          value: "type",
          sortable: false,
        });
      }

      if (this.pShowUserActions) {
        defaultHeaders.push({ text: "Actions", value: "actions", sortable: false });
      }

      return defaultHeaders;
    },
  },

  methods: {
    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.pLocalStorageTable,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.$emit("onLoadPage", currentPage);
      this.tableInited = true;
    },

    activateUser(user) {
      this.$refs.confirmationDialog.openDialog({
        header: "Activate confirmation",
        text: `Are you sure you want to activate "${user.firstName} ${user.lastName}"?`,
        submitText: "Activate",
        submitColor: "green",
        onSubmit: async () => {
          try {
            await this.$store.dispatch(`${this.pStoreRef}/activateUser`, user);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },

    openDeleteUserDialog(user) {
      this.$refs.confirmationDialog.openDialog({
        header: "Deactivate confirmation",
        text: `Are you sure you want to deactivate "${user.firstName} ${user.lastName}"?`,
        submitText: "Deactivate",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch(`${this.pStoreRef}/deleteUser`, user);
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.host-membership {
  white-space: nowrap;
}

.item-circle {
  height: 20px;
  width: 20px;
  background: black;
  border-radius: 50%;
}
</style>
